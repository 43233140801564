import { kebabCase, upperFirst, camelCase } from "lodash";
import { Android, Native, iOS } from "../resources/Config";
import { containsMobileTech } from "./Technology";
import { DevOpsRepo } from "../resources/Types";

export const getAppCenterOrg = (brand: string): string => {
  switch (brand) {
    case "Volvo Trucks":
      return "VTC";
    case "Mack Trucks":
      return "MACK";
    case "Volvo Bus":
      return "Bus";
    case "Nova Bus":
      return "NovaBus";
    case "AB Volvo":
    case "Volvo Group":
      return "VG";
    case "UD Trucks":
      return "UD";
    case "Renault Trucks":
      return "RT";
    case "Prevost":
      return "Prevost";
    case "Volvo CE":
      return "VCE";
    case "Volvo Penta":
      return "VP";
    default:
      return "";
  }
};

export const getAppCenterPlatform = (platform: string, technology: string): string => {
  switch (technology) {
    case "Native":
      return platform === Android ? "Java" : "Objective-C-Swift";
    case "React Native":
      return "React-Native";
    default:
      return technology;
  }
};

type AppCenterAppIdCfg = {
  name: string;
  platform: string;
  region: string[];
  release: string;
};
export const getAppCenterAppId = ({ name, region, platform, release }: AppCenterAppIdCfg): string =>
  kebabCase(
    `${name.toLowerCase()} ${
      region.length === 1 ? `${region[0].toLowerCase()} ` : ""
    }$${platform.toLowerCase()} ${release.toLowerCase()}`,
  );

const getPascalCase = (value: string): string => upperFirst(camelCase(value));

const getRepoName = (name: string, tech: string): string =>
  getPascalCase(name) + (tech === iOS ? iOS : getPascalCase(tech));

export const getDevOpsRepos = (
  name: string,
  platform: string[],
  technology: string,
  includesBackend: boolean,
): DevOpsRepo[] => {
  const repos = [];
  if (platform.includes(iOS) && technology === Native) {
    repos.push({
      type: iOS,
      name: getRepoName(name, iOS),
    });
  }

  if (platform.includes(Android) && technology === Native) {
    repos.push({
      type: Android,
      name: getRepoName(name, Android),
    });
  }

  if (containsMobileTech(platform) && technology !== Native) {
    repos.push({
      type: technology,
      name: getRepoName(name, technology),
    });
  }

  if (platform.includes("Web")) {
    repos.push({ type: "Web", name: getRepoName(name, "Web") });
  }

  if (includesBackend === true) {
    repos.push({ type: "Backend", name: getRepoName(name, "Backend") });
  }

  return repos;
};
