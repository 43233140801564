import React from "react";
import axios from "axios";
import update from "immutability-helper";
// @ts-ignore
import { SceneWrapper, Sidepanel } from "airr-react";
import Auth from "volvo-ping-auth-helper";

import FrontView from "../views/FrontView";
import LoginView from "../views/LoginView";
import AppsFilter from "../ui/AppsFilter";
import { viewName as PortfolioViewName } from "../views/main-scene/Portfolio";
import { Routes, baseUrl, appCenterUrl, devOpsUrl } from "../resources/Config";
import { initialState } from "../providers/AppManager";
import {
  DATA_APP_UPDATED,
  DATA_ADD_APP,
  DATA_DELETE_APP,
  DATA_APP_UPDATE,
  DATA_LIST_FILTERED,
  DATA_TEXT_SEARCH_CHANGE,
  APP_URL_CHANGED,
  PAGINATION_INCREMENT_PAGE,
  TOGGLE_SHOW_FILTERS,
  DATA_LIST_CLEARED,
  RENDER_MODE_CHANGED,
  APP_DATA_REVERT_CHANGES,
} from "../resources/AppEvents";
import { FiltersSelection, InitialState } from "../providers/Types";
import { App } from "../resources/Types";
import { apiUrl, getAuthHeader } from "../resources/Config";
import { containsMobileTech } from "../utils/Technology";
import { getAppCenterPlatform, getAppCenterAppId } from "../utils/Integration";
import cleanInputValue from "../utils/Sanitization";
import { AppCenterApp, DevOpsRepo } from "../resources/Types";

const FrontViewName = "front-view";
const LoginViewName = "login-view";
const AppViewName = "app-view";

export type NewApp = {
  name: string;
  brand: string;
  region: string[];
  platform: string[];
  technology?: string;
  appCenterName: string;
  devOpsRepos: DevOpsRepo[];
  managedBy: string;
  existingAppCenterApps?: AppCenterApp[];
  existingDevOpsRepos?: Repo[];
};

type Repo = {
  name: string;
  webUrl?: string;
};

type Props = {};
type State = {};

export default class Viewport extends SceneWrapper<Props, State> {
  refCOMPMainScene = React.createRef();
  state: any;

  constructor(props: {}) {
    super(props);

    this.state = {
      ...this.state,
      views: [
        Object.assign({}, this.viewsConfig[LoginViewName]),
        Object.assign({}, this.viewsConfig[FrontViewName]),
      ],
      activeViewName: LoginViewName,
      navbar: -1,
      backButton: true,
      navbarHeight: 0,
      stackMode: true,
      animation: "overlay",
      sidepanel: {
        type: Sidepanel,
        props: {
          enabled: false,
          children: null,
          side: "left",
          sizeFactor: 0.6,
        },
      },
      appCenterIssue: false,
    };
  }

  reduceFilters = [
    "region",
    "platform",
    "device",
    "teamLeader",
    "solutionLeader",
    "targetGroup",
    "BSPM",
    "businessKeyUser",
    "businessProcess",
    "deliveryManager",
    "domainManager",
    "appStatus",
    "appCategory",
    "managedBy",
  ];

  filterAppByKey(selection: {}, app: {}, key: string) {
    if (selection[key] && selection[key].length) {
      let has_val = false;
      has_val = selection[key].reduce((prev, curr) => {
        if (app[key] && app[key].indexOf(curr) !== -1) {
          return true;
        }

        return prev;
      }, false);
      return has_val;
    } else {
      return true;
    }
  }

  AppUpdatedListener = (updateChange: { apps: []; updatedAppIndex: number }) => {
    if (updateChange) {
      const { apps, updatedAppIndex } = updateChange;
      if (apps && updatedAppIndex >= 0) {
        //::changeView will actually update the props of current AppView

        this.changeView(AppViewName, {
          app: apps[updatedAppIndex],
        });
      }
    }
  };

  goToRoute = (path: string) => {
    return this.props.appManager.goTo(path);
  };

  goToApp = (appId: number) => {
    return this.goToRoute(Routes.app.replace(":id", appId));
  };

  RenderModeChangedListener = ({ renderMode }: { renderMode: string }) => {
    if (renderMode === "mobile") {
      this.setState({
        sidepanel: update(this.state.sidepanel, {
          props: {
            enabled: {
              $set:
                this.refCOMPMainScene.current &&
                this.refCOMPMainScene.current.state &&
                this.refCOMPMainScene.current.state.activeViewName === PortfolioViewName
                  ? true
                  : false,
            },
            children: { $set: this.renderAppsFilter },
          },
        }),
      });
    } else if (this.state.sidepanel.props.isShown) {
      this.hideSidepanel().then(this.disableAndResetPanelContent);
    } else {
      this.disableAndResetPanelContent();
    }
  };

  disableAndResetPanelContent = () => {
    this.setState({
      sidepanel: update(this.state.sidepanel, {
        props: {
          isShown: { $set: false },
          enabled: { $set: false },
          children: { $set: null },
        },
      }),
    });
  };

  AppUrlChangeListener = ({ url }: { url: string }) => {
    if (Auth.hasIdentity()) {
      if (
        Routes.homeTest.test(url) ||
        Routes.portfolioTest.test(url) ||
        Routes.adminTest.test(url) ||
        Routes.appTest.test(url)
      ) {
        if (this.props.appState.renderMode === "mobile") {
          if (Routes.portfolioTest.test(url)) {
            if (!this.state.sidepanel.enabled) {
              this.enableSidepanel();
            }
          } else if (
            this.refCOMPMainScene.current &&
            this.refCOMPMainScene.current.state &&
            this.refCOMPMainScene.current.state.activeViewName === PortfolioViewName
          ) {
            this.disableSidepanel();
          }
        }

        if (this.state.activeViewName !== FrontViewName) {
          this.changeView(FrontViewName, {}).then(() => {
            this.setState({
              navbar: -1,
              views: this.state.views.filter((item) => item.props.name === FrontViewName),
            });
          });
          return;
        }
      }
    } else {
      if (url === baseUrl) {
        this.changeView(LoginViewName).then(() => {
          this.props.appManager.triggerEvent(
            DATA_LIST_CLEARED,
            Object.assign({}, initialState, { url: baseUrl }),
          );
        });
        return;
      }
    }

    this.propagatePageNotFoundFlag();
  };

  registerAppEventsListeners = () => {
    this.props.appManager.addEventListener(DATA_APP_UPDATED, this.AppUpdatedListener);
    this.props.appManager.addEventListener(APP_URL_CHANGED, this.AppUrlChangeListener);
    this.props.appManager.addEventListener(RENDER_MODE_CHANGED, this.RenderModeChangedListener);
  };

  removeAppEventsListeners = () => {
    this.props.appManager.removeEventListener(RENDER_MODE_CHANGED, this.RenderModeChangedListener);
    this.props.appManager.removeEventListener(APP_URL_CHANGED, this.AppUrlChangeListener);
    this.props.appManager.removeEventListener(DATA_APP_UPDATED, this.AppUpdatedListener);
  };

  windowResizeListener = () => {
    if (this.props && this.props.appManager) {
      if (window.innerWidth < 992) {
        if (this.props.appState.renderMode !== "mobile") {
          this.props.appManager.triggerEvent(RENDER_MODE_CHANGED, {
            renderMode: "mobile",
          });
        }
      } else {
        if (this.props.appState.renderMode !== "desktop") {
          this.props.appManager.triggerEvent(RENDER_MODE_CHANGED, {
            renderMode: "desktop",
          });
        }
      }
    }
  };

  componentDidMount() {
    super
      .componentDidMount() //super.componentDidMount will update sidepanel props so we wait for this to happen
      .then(() =>
        //then we set first, default render mode dependant properties (which include sidepanel props)
        this.RenderModeChangedListener({
          renderMode: this.props.appState.renderMode,
        }),
      );

    window.addEventListener("resize", this.windowResizeListener);
    this.registerAppEventsListeners();
  }

  componentWillUnmount() {
    this.removeAppEventsListeners();

    window.removeEventListener("resize", this.windowResizeListener);
    this.unmounted = true;
  }

  propagatePageNotFoundFlag() {
    const loginViewIndex = this.state.views.findIndex((view) => view.props.name === LoginViewName);

    if (loginViewIndex !== -1) {
      this.setState({
        views: update(this.state.views, {
          [loginViewIndex]: { props: { pageNotFound: { $set: true } } },
        }),
      });
    }
  }

  generateAppCenterApps = async (
    name: string,
    appCenterName: string,
    platform: string[],
    region: string[],
    technology: string,
  ): Promise => {
    const apiCalls = [];
    const releases = ["Beta", "Store"];
    platform
      .filter((p) => containsMobileTech(p))
      .forEach((p) => {
        releases.forEach((release) => {
          const appCenterRequestObject = {
            description: appCenterName,
            release_type: release,
            display_name: appCenterName,
            name: getAppCenterAppId({ name, platform: p, region, release }),
            os: p,
            platform: getAppCenterPlatform(p, technology),
          };

          const request = axios.post(
            appCenterUrl,
            {
              app: JSON.stringify(appCenterRequestObject),
            },
            {
              headers: getAuthHeader(Auth.token),
            },
          );

          apiCalls.push(request);
        });
      });
    return Promise.all(apiCalls);
  };

  generateDevOpsRepos = async (devOpsRepos: DevOpsRepo[]): Promise => {
    const apiCalls = [];

    devOpsRepos.forEach((repo) => {
      const request = axios.post(
        devOpsUrl,
        {
          repo: JSON.stringify({ name: repo.name }),
        },
        {
          headers: getAuthHeader(Auth.token),
        },
      );

      apiCalls.push(request);
    });
    return Promise.all(apiCalls);
  };

  findExistingAppCenterApps = (existingAppCenterApps, appCenterName) => {
    const matchedApps = [];
    for (const existingApp of existingAppCenterApps) {
      if (existingApp.display_name.toLowerCase() === appCenterName.toLowerCase()) {
        matchedApps.push(existingApp);
      }
    }

    return matchedApps;
  };

  handleSaveNewApp = async ({
    name,
    brand,
    region,
    platform,
    technology,
    appCenterName,
    devOpsRepos,
    managedBy,
    existingAppCenterApps = [],
    existingDevOpsRepos = [],
  }: NewApp) => {
    let appCenterApps = [];
    let result = [];

    const alreadyExistingAppCenterApps = this.findExistingAppCenterApps(
      existingAppCenterApps,
      appCenterName,
    );

    if (alreadyExistingAppCenterApps.length > 0) {
      result = alreadyExistingAppCenterApps;
    } else {
      result = await this.generateAppCenterApps(name, appCenterName, platform, region, technology);
    }

    try {
      this.setState({ appCenterIssue: false });

      appCenterApps = result.map((item) => {
        const { name: n, display_name, platform: p, os, release_type } = item.data || item;
        return {
          name: n,
          display_name,
          platform: p,
          os,
          release_type,
        };
      });
    } catch (e) {
      this.setState({ appCenterIssue: true });
    }

    let finalDevOpsRepos = [];

    if (!this.state.appCenterIssue) {
      const duplicateRepos = [];
      let newRepos = devOpsRepos;

      for (const repo of devOpsRepos) {
        const duplicateRepo = existingDevOpsRepos.find((existing) => existing.name === repo.name);
        if (duplicateRepo) {
          duplicateRepos.push(duplicateRepo);
          newRepos = newRepos.filter((r) => r.name !== repo.name);
        }
      }

      // Link existing repos
      finalDevOpsRepos = finalDevOpsRepos.concat(
        duplicateRepos.map((item) => ({
          type: devOpsRepos.find((repo) => repo.name === item.name).type || "",
          link: item.webUrl || "",
        })),
      );

      // Generate new repos
      const repos = await this.generateDevOpsRepos(newRepos);

      finalDevOpsRepos = finalDevOpsRepos.concat(
        repos.map((item) => ({
          type: devOpsRepos.find((repo) => repo.name === item.data.name).type || "",
          link: item.data.webUrl,
        })),
      );

      const exampleApp = this.props.appState.apps[0];
      const newApp = Object.keys(exampleApp).reduce((prev, curr) => {
        const type = typeof exampleApp[curr];
        prev[curr] =
          type === "string"
            ? ""
            : Array.isArray(exampleApp[curr])
              ? []
              : type === "number" || exampleApp[curr] === null
                ? null
                : {};
        return prev;
      }, {});

      newApp.name = name.trim();
      newApp.brand = brand.trim();
      newApp.region = region;
      newApp.platform = platform;
      newApp.managedBy = managedBy;
      newApp.appStatus = "Production";

      newApp.devOpsRepos = finalDevOpsRepos;
      newApp.appCenterApps = appCenterApps;

      newApp.artworks = {
        "512": "",
      };

      newApp.screenshots = {
        ipad: [],
        common: [],
      };
      newApp.description = {
        common: "",
        store: "",
      };

      const formData = new FormData();
      formData.append("app", JSON.stringify(newApp));

      axios
        .put(apiUrl, formData, {
          headers: getAuthHeader(Auth.token),
        })
        .then((res) => {
          const app = res.data;
          this.props.appManager.triggerEvent(DATA_ADD_APP, {
            apps: update(this.props.appState.apps, {
              $unshift: [app],
            }),
          });

          this.goToApp(app.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  triggerAppDeleteEvent(app_index: number) {
    this.props.appManager.triggerEvent(DATA_DELETE_APP, {
      apps: update(this.props.appState.apps, {
        $splice: [[app_index, 1]],
      }),
    });
  }

  handleRevertChanges = () => {
    this.props.appManager.triggerEvent(APP_DATA_REVERT_CHANGES, {});
  };

  handleUpdateApp = () => {
    this.props.appManager.triggerEvent(DATA_APP_UPDATE);
  };

  handleBackButton = () => {
    return this.goToRoute(Routes.portfolio + this.getFiltersSelectionUrlSuffix());
  };

  triggerResetPaginationPage = () => {
    this.props.appManager.triggerEvent(PAGINATION_INCREMENT_PAGE, {
      page: 1,
    });
  };

  triggerIncrementPaginationPage = () => {
    if (this.props.appState.size * this.props.appState.page < this.props.appState.apps.length) {
      this.props.appManager.triggerEvent(PAGINATION_INCREMENT_PAGE, {
        page: this.props.appState.page + 1,
      });
    }
  };

  renderAppsFilter = (extraStyle: {} = {}) => {
    const props = {
      getApps: this.getFilteredApps,
      handleFilterChange: this.handleFilterChange,
      dispatchFilterChange: this.dispatchFilterChange,
      appState: this.props.appState,
      displayName: "AppsFilter",
    };

    return <AppsFilter {...props} style={extraStyle} />;
  };

  getFilters = (array: string[], value: string): string[] => {
    return array.includes(value)
      ? array.filter((item: string) => item !== value)
      : [value, ...array];
  };

  handleFilterChange = (filterName: string, filterValue: string) => {
    // $FlowFixMe
    const currentPropFilters = this.props.appState.filterSelection[filterName] || [];
    const updatedPropFilters = this.getFilters(currentPropFilters, filterValue);
    const filters = Object.assign({}, this.props.appState.filterSelection, {
      [filterName]: updatedPropFilters,
    });

    this.dispatchFilterChange(filters);
  };

  dispatchFilterChange = (filterSelection: FiltersSelection) => {
    this.props.appManager.triggerEvent(DATA_LIST_FILTERED, {
      filterSelection,
    });

    const search = this.getQueryFromFiltersSelection(filterSelection);

    this.props.history.replace({ search });

    this.triggerResetPaginationPage();
  };

  dispatchTextSearchChange = (textSearch: string) => {
    const cleanedTextSearch = cleanInputValue(textSearch);
    if (cleanedTextSearch.length > 0) {
      this.props.appManager.triggerEvent(DATA_TEXT_SEARCH_CHANGE, {
        textSearch: cleanedTextSearch,
        page: 1,
      });

      const { filterSelection } = this.props.appState;

      //update second value in filters, that one will be use in url query string
      //so serach key will be used
      const filters = update(filterSelection, {
        search: { $set: cleanedTextSearch },
      });
      this.dispatchFilterChange(filters);
    }
  };

  dispatchClearAllFilters = () => {
    const { filterSelection } = this.props.appState;
    this.props.appManager.triggerEvent(DATA_TEXT_SEARCH_CHANGE, {
      textSearch: "",
    });
    this.props.appManager.triggerEvent(DATA_LIST_FILTERED, {
      filterSelection:
        filterSelection.appStatus && filterSelection.appStatus.includes("Production")
          ? { appStatus: ["Production"] }
          : {},
    });
    this.props.history.replace({
      search: "",
    });
  };

  getQueryFromFiltersSelection(filterSelection: {}) {
    return Object.entries(filterSelection).reduce((prev, [key, val]) => {
      if (val) {
        if (Array.isArray(val) && val.length) {
          return prev + (prev ? "&" : "") + key + "=" + val.join(",");
        }

        if (typeof val === "string" && val.length) {
          return prev + (prev ? "&" : "") + key + "=" + val;
        }

        if (typeof val === "boolean") {
          return prev + (prev ? "&" : "") + key + "=" + String(val);
        }
      }

      return prev;
    }, "");
  }

  getFiltersSelectionUrlSuffix = () => {
    if (this.props.appState && this.props.appState.filterSelection) {
      const query = this.getQueryFromFiltersSelection(this.props.appState.filterSelection);
      if (query) {
        return "?" + query;
      }
    }

    return "";
  };

  isTextUsedToDescribeTheApp = (app: App, text: string): boolean => {
    const { name, description, otherAppNames, devOpsRepos, appCenterApps } = app;
    const { common, store } = description;
    const lowercasedText = text.toLowerCase();
    return (
      name.toLowerCase().indexOf(lowercasedText) >= 0 ||
      (common && common.toLowerCase().indexOf(lowercasedText) >= 0) ||
      (store && store.toLowerCase().indexOf(lowercasedText) >= 0) ||
      otherAppNames.toLowerCase().indexOf(lowercasedText) >= 0 ||
      devOpsRepos
        .map((repo) => repo.link.toLowerCase())
        .join(",")
        .indexOf(lowercasedText) >= 0 ||
      appCenterApps
        .map((a: AppCenterApp) => a.display_name.toLowerCase())
        .join(",")
        .indexOf(lowercasedText) >= 0
    );
  };

  getSortedApps = (): App[] => {
    const { textSearch } = this.props.appState;
    const sortedApps = this.getFilteredApps();

    if (sortedApps && textSearch) {
      const lowercasedText = textSearch.toLowerCase();
      sortedApps.sort((app1, app2) => {
        const { name: name1, otherAppNames: otherNames1, description: desc1 } = app1;
        const { name: name2, otherAppNames: otherNames2, description: desc2 } = app2;
        const { common1, store1 } = desc1;
        const { common2, store2 } = desc2;
        if (
          name1.toLowerCase().indexOf(lowercasedText) >= 0 &&
          name2.toLowerCase().indexOf(lowercasedText) < 0
        ) {
          return -1;
        } else if (
          otherNames1.toLowerCase().indexOf(lowercasedText) >= 0 &&
          otherNames2.toLowerCase().indexOf(lowercasedText) < 0
        ) {
          return -1;
        } else if (
          ((common1 && common1.toLowerCase().indexOf(lowercasedText)) >= 0 ||
            (store1 && store1.toLowerCase().indexOf(lowercasedText) >= 0)) &&
          ((common2 && common2.toLowerCase().indexOf(lowercasedText)) < 0 ||
            (store2 && store2.toLowerCase().indexOf(lowercasedText) < 0))
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    return sortedApps;
  };

  getFilteredApps = (): App[] => {
    const { apps, filterSelection, textSearch } = this.props.appState;

    if (apps && apps.length) {
      return apps.filter((app) => {
        if (textSearch && !this.isTextUsedToDescribeTheApp(app, textSearch)) {
          return false;
        }

        if (
          filterSelection.brand &&
          filterSelection.brand.length &&
          filterSelection.brand.indexOf(app.brand) === -1
        ) {
          return false;
        }

        const include_app = this.reduceFilters.reduce((prev, curr) => {
          if (!this.filterAppByKey(filterSelection, app, curr)) {
            return false;
          }

          return prev;
        }, true);

        if (!include_app) {
          return false;
        }

        return !(
          filterSelection.apmID &&
          filterSelection.apmID.length &&
          (!app.apmID ||
            (typeof app.apmID === "number" &&
              app.apmID.toString().indexOf(filterSelection.apmID) === -1))
        );
      });
    }

    return [];
  };

  triggerToggleShowFilters = () => {
    this.props.appManager.triggerEvent(TOGGLE_SHOW_FILTERS, {
      showFilters: !this.props.appState.showFilters,
    });
  };

  getAppState = (): InitialState => this.props.appState;

  getAppCenterIssue = (): boolean => this.state.appCenterIssue;

  viewsConfig = {
    [LoginViewName]: {
      type: LoginView,
      props: {
        name: LoginViewName,
        appManager: this.props.appManager,
      },
    },
    [FrontViewName]: {
      type: FrontView,
      props: {
        name: FrontViewName,
        refCOMPMainScene: this.refCOMPMainScene,
        mainSceneProps: {
          handleUpdateApp: this.handleUpdateApp,
          handleRevertChanges: this.handleRevertChanges,
          handleBackButton: this.handleBackButton,
          disableSidepanel: this.disableSidepanel,
          enableSidepanel: this.enableSidepanel,
          openSidepanel: this.openSidepanel,
          renderAppsFilter: this.renderAppsFilter,
          handleSaveNewApp: this.handleSaveNewApp,
          getAppCenterIssue: this.getAppCenterIssue,
          getApps: this.getSortedApps,
          loadNextApps: this.triggerIncrementPaginationPage,
          url: this.props.appState.url,
          getAppState: this.getAppState,
          appManager: this.props.appManager,
          dispatchClearAllFilters: this.dispatchClearAllFilters,
          handleFilterChange: this.handleFilterChange,
          dispatchFilterChange: this.dispatchFilterChange,
          dispatchTextSearchChange: this.dispatchTextSearchChange,
          triggerToggleShowFilters: this.triggerToggleShowFilters,
          goToApp: this.goToApp,
          disableGUI: this.disableGUI,
          enableGUI: this.enableGUI,
        },
        getFiltersSelectionUrlSuffix: this.getFiltersSelectionUrlSuffix,
      },
    },
  };
}
