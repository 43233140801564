import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import Auth from "volvo-ping-auth-helper";
import { appCenterUrl, getAuthHeader } from "../../resources/Config";
import { SmallActionButton, SmallActionSecondaryButton, AppPortalModal } from "../Common";
import { AppCenterApp } from "../../resources/Types";
import AppFilterField from "../AppFilterField";

type State = {
  id: string;
  existingAppCenterApps: AppCenterApp[];
};
type Props = {
  show: boolean;
  appId: string;
  handleSave: (app: AppCenterApp) => void;
  closeForm: () => void;
};

export default class AddAppCenterModal extends Component<Props, State> {
  state = {
    id: "",
    existingAppCenterApps: [],
  };

  componentDidMount = () => {
    axios
      .get(appCenterUrl, {
        headers: getAuthHeader(Auth.token),
      })
      .then((results) => {
        this.setState({ id: this.props.appId, existingAppCenterApps: results.data });
      });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.appId !== this.props.appId) {
      this.setState({ id: this.props.appId });
    }
  };

  handlePropertyChange = (property: string) => (value: string) => {
    this.setState({ [property]: value });
  };

  clear = () => {
    this.setState({ id: "" });
  };

  handleClose = () => {
    this.clear();
    this.props.closeForm();
  };

  handleSaveNewApp = () => {
    const { id, existingAppCenterApps } = this.state;
    const app = existingAppCenterApps.find((a) => a.name === id);

    if (app) {
      this.props.handleSave({
        name: id,
        display_name: app.name,
        os: app.os,
        platform: app.platform,
        release_type: app.release_type,
      });
      this.clear();
    }
  };

  formDataValid = () => {
    const { id, existingAppCenterApps } = this.state;
    return id !== "" && existingAppCenterApps.map((app) => app.name).includes(id);
  };

  render() {
    const { id, existingAppCenterApps } = this.state;
    const apps = existingAppCenterApps.map((app) => app.name);
    return (
      <AppPortalModal title="Add new AppCenter app" size="lg" isOpen={this.props.show}>
        <Container>
          <Row>
            <Col lg="6" className="m-lg-auto">
              <Row>
                <Col>
                  <AppFilterField
                    inEditState={true}
                    name="AppCenter name"
                    selected={id}
                    fieldType="autocomplete"
                    items={apps}
                    onChange={this.handlePropertyChange("id")}
                  >
                    <br />
                  </AppFilterField>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col className="d-flex justify-content-between">
                  <SmallActionSecondaryButton text="Cancel" onClick={this.handleClose} />

                  <SmallActionButton
                    text="Confirm"
                    onClick={this.handleSaveNewApp}
                    disabled={!this.formDataValid()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </AppPortalModal>
    );
  }
}
