import React, { SyntheticEvent } from "react";
// @ts-ignore
import { ViewWrapper } from "airr-react";
import "../../../css/views/Portfolio.css";
import { Generic } from "fp-ui-components-react";
import { Util } from "../../resources/Util";
import { pictureScaleUrl } from "../../resources/Config";
import ImageThumbnail from "../../ui/Common/ImageThumbnail/ImageThumbnail";
import FilterBtn from "../../ui/FilterBtn";
import AddNewAppBtn from "../../ui/Portfolio/AddNewAppBtn";
import AddAppForm from "../../ui/Portfolio/AddAppForm";
import {
  Header,
  SearchForm,
  FilterButton,
  SmallActionSecondaryButton,
  AppPortalModal,
} from "../../ui/Common";
import { Platforms, Devices, Rating } from "../../ui/Utils";

import { InitialState } from "../../providers/Types";
import { App, NewApp } from "../../resources/Types";
import cleanInputValue from "../../utils/Sanitization";

export const viewName = "portfolio-view";

type Props = {
  getAppState: () => InitialState;
  loadNextApps: () => void;
  setScrollOnBottomHandler: () => void;
  scrollToTop: () => void;
  openSidepanel: () => void;
  triggerToggleShowFilters: () => void;
  handleSaveNewApp: (app: NewApp) => void;
  getAppCenterIssue: () => boolean;
  handleFilterChange: (property: string, value: string) => void;
  dispatchTextSearchChange: (textSearch: string) => void;
  goToApp: (appId: number) => void;
};

type State = {
  showNewAppModal: boolean;
};
export default class Portfolio extends ViewWrapper<Props, State> {
  state = {
    showNewAppModal: false,
  };

  handleFrontViewOnBottom = () => {
    this.props.loadNextApps();
  };

  toggleAddNewAppForm = () => {
    this.setState({ showNewAppModal: !this.state.showNewAppModal });
  };

  handleSaveNewApp = async (app: NewApp) => {
    await this.props.handleSaveNewApp(app);
    if (!this.props.getAppCenterIssue()) {
      this.toggleAddNewAppForm();
    }
  };

  viewAfterActivation() {
    const { search } = Util.querySearchToObject(window.location.search);

    if (search && Array.isArray(search) && search.length > 0) {
      this.props.dispatchTextSearchChange(search[0]);
    }

    this.props.setScrollOnBottomHandler(this.handleFrontViewOnBottom);
    this.props.scrollToTop();
  }

  viewBeforeDeactivation() {
    this.props.setScrollOnBottomHandler(null);
  }

  getAppDescription = (app: App) => {
    const desc =
      app.description && app.description.common
        ? app.description.common
        : app.description && app.description.store
          ? app.description.store
          : "";

    return desc.length > 200 ? desc.substr(0, 200) + " (...)" : desc;
  };

  getAppRating = (app: App) => {
    if (app.androidUserRatingCount !== 0 || app.iOSUserRatingCount !== 0) {
      return (
        (app.androidUserRatingCount * app.androidAverageUserRating +
          app.iOSUserRatingCount * app.iOSAverageUserRating) /
        (app.androidUserRatingCount + app.iOSUserRatingCount)
      );
    } else {
      return 0.0;
    }
  };

  getAppRatingCount = (app: App) => {
    return app.androidUserRatingCount + app.iOSUserRatingCount;
  };

  getAppPromoImage(app: App): string {
    let pictureUrl = "";
    let key = "artwork512";

    if (app.artworks && app.artworks["512"]) {
      pictureUrl = app.artworks["512"];
    } else if (app.screenshots && app.screenshots.common && app.screenshots.common.length) {
      pictureUrl = app.screenshots.common[0];
      key = "screenshot-common-0";
    } else if (app.screenshots && app.screenshots.ipad && app.screenshots.ipad.length) {
      pictureUrl = app.screenshots.ipad[0];
      key = "screenshot-ipad-0";
    }

    if (pictureUrl) {
      return `${pictureScaleUrl}?url=${pictureUrl}&width=112&appId=${app.id}&key=${key}`;
    }

    return pictureUrl;
  }

  handleTextSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    try {
      const cleanedCode = cleanInputValue(value);
      if (cleanedCode.length > 0) {
        this.props.dispatchTextSearchChange(value);
      }
    } catch (err) {
      console.log(err);
    }
    this.props.dispatchTextSearchChange(value);
  };

  renderOptionsCtn(appsAreFiltrated: boolean, appsLength: number) {
    const style = {
      top: this.props.getStickMenu() ? this.props.getMenuHeight() : "",
    };

    const filters = this.props.getAppState().filterSelection;

    const filterValues = Object.values(filters).reduce((array, singleFilters) => {
      return array.concat(...singleFilters);
    }, []);

    return (
      <div className="options-ctn" style={style}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row search-row">
                <div className="mr-3">
                  <Header text="Portfolio" />
                </div>

                <SearchForm
                  placeholder="What are you looking for?"
                  initialText={this.props.getAppState().textSearch}
                  onClick={this.props.dispatchTextSearchChange}
                />
              </div>
              <div className="row filters-row">
                <>
                  {Object.entries(filters).map(([key, values]) => {
                    if (Array.isArray(values)) {
                      return values.map((value) => (
                        <FilterButton
                          key={`${key}-${value}`}
                          text={value}
                          onClick={() => {
                            this.props.handleFilterChange(key, value);
                          }}
                        />
                      ));
                    }

                    if (typeof values === "string" && values.length > 0) {
                      return (
                        <FilterButton
                          key={`${key}-${values}`}
                          text={values}
                          onClick={() => {
                            this.props.dispatchTextSearchChange("");
                          }}
                        />
                      );
                    }

                    return null;
                  })}
                  {filterValues.length > 0 ? (
                    <>
                      <hr />
                      <FilterButton text="CLEAR ALL" onClick={this.props.dispatchClearAllFilters} />
                    </>
                  ) : null}
                </>
              </div>
              <div className="row buttons-row">
                <FilterBtn
                  renderMode={this.props.getAppState().renderMode}
                  showFilters={this.props.getAppState().showFilters}
                  openSidepanel={this.props.openSidepanel}
                  triggerToggleShowFilters={this.props.triggerToggleShowFilters}
                />
                <AddNewAppBtn handleAddNewAppClick={this.toggleAddNewAppForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderApps(apps: App[]) {
    if (apps) {
      return (
        <div
          className="row apps-ctn"
          style={{
            paddingTop: this.props.getStickMenu() ? this.props.getMenuHeight() : "",
          }}
        >
          {apps
            .filter(
              (item, index) =>
                index < this.props.getAppState().size * this.props.getAppState().page,
            )
            .map((app: App) => {
              const promoImg = this.getAppPromoImage(app);
              const description = this.getAppDescription(app);

              return (
                <div className="col-12 app-block" key={app.id}>
                  <ImageThumbnail url={promoImg} />

                  <div className="app-details">
                    <div className="app-info">
                      <div className="app-basic-info">
                        <div className="name">{app.name.trim()}</div>
                        <div className="brand">
                          {app.brand + " | APM ID: "}
                          {app.apmID ? app.apmID : "-"}
                        </div>
                      </div>
                      <div className="description">{description}</div>
                      <div className="button">
                        <SmallActionSecondaryButton
                          text="More"
                          onClick={() => {
                            this.props.goToApp(app.id);
                          }}
                        />
                      </div>
                    </div>
                    <div className="app-platforms-devices">
                      <div className="platforms">
                        <Platforms platforms={app.platform} />
                      </div>
                      <div className="devices">
                        <Devices devices={app.device} />
                      </div>
                      <div>
                        <Rating
                          rating={this.getAppRating(app)}
                          count={this.getAppRatingCount(app)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }

    return <></>;
  }

  content() {
    const apps = this.props.getApps();
    const appsAreFiltrated = Boolean(
      this.props.getAppState().textSearch ||
        Object.values(this.props.getAppState().filterSelection).length,
    );
    const className = viewName + (this.props.getAppState().showFilters ? " filters-shown" : "");
    const filtersColClass = `${
      this.props.getAppState().showFilters ? "d-none d-lg-block col-lg-3 col-xl-2" : "d-none"
    } filters-col`;

    const appsFiltersStyle = {
      top: this.props.getStickMenu() ? this.props.getMenuHeight() : "",
    };

    return (
      <div className={className}>
        <div className="container-fluid">
          <div className="row">
            <div className={filtersColClass}>{this.props.renderAppsFilter(appsFiltersStyle)}</div>
            <div
              className="col-12 col-lg-9 col-xl-8"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              {this.renderOptionsCtn(appsAreFiltrated, apps ? apps.length : 0)}

              {this.props.getAppState().apps ? this.renderApps(apps) : <Generic.Loader />}
            </div>
          </div>
        </div>
        <AppPortalModal title="Add new application" size="xl" isOpen={this.state.showNewAppModal}>
          <AddAppForm
            handleSaveNewApp={this.handleSaveNewApp}
            getAppState={this.props.getAppState}
            closeForm={this.toggleAddNewAppForm}
            getAppCenterIssue={this.props.getAppCenterIssue}
          />
        </AppPortalModal>
      </div>
    );
  }
}
