import React from "react";
import * as Device from "../../../utils/Device";
import googlePlay from "./googleplay.png";
import appStore from "./appstore.png";
import intune from "./intune.png";
import styles from "./Badge.module.css";
import Rating from "../../Utils/Rating/Rating";

type Props = {
  bundleId?: string;
  link?: string;
  rating: number;
  count: number;
};

const isAndroid = Device.isAndroidDevice();
const isIos = Device.isIosDevice();
const isMobile = Boolean(isAndroid || isIos);
const isDesktop = !isMobile;

export function GooglePlayBadge({ bundleId, link, rating, count }: Props) {
  const finalLink = bundleId ? `https://play.google.com/store/apps/details?id=${bundleId}` : link;
  return Boolean(finalLink) && (isAndroid || isDesktop) ? (
    <a href={finalLink} title="Get it on Google Play" target="_blank" rel="noopener noreferrer">
      <div
        className={styles.badge}
        style={{
          backgroundImage: `url(${googlePlay})`,
        }}
      ></div>
      <Rating rating={rating} count={count} />
    </a>
  ) : null;
}

export function AppStoreBadge({ link, rating, count }: Props) {
  return Boolean(link) && (isIos || isDesktop) ? (
    <a href={link} title="Download on the App Store" target="_blank" rel="noopener noreferrer">
      <div
        className={styles.badge}
        style={{
          backgroundImage: `url(${appStore})`,
        }}
      ></div>
      <Rating rating={rating} count={count} />
    </a>
  ) : null;
}

export function InTuneBadge({ bundleId }: Props) {
  return bundleId ? (
    <a
      href={`https://portal.manage.microsoft.com/apps/${bundleId}`}
      title="Company Portal"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className={`${styles.badge} ${styles.intune}`}
        style={{
          backgroundImage: `url(${intune})`,
        }}
      >
        <span>Download from</span>
        <span>Intune</span>
        <span>Company Portal</span>
      </div>
    </a>
  ) : null;
}
